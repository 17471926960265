import React, { FunctionComponent } from 'react';
import { Layout, Register } from '../components';

const RegisterPage: FunctionComponent = () => (
  <Layout>
    <Register />
  </Layout>
);

export default RegisterPage;
